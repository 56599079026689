export { GlobalStyles } from "./globalstyles"
export * from "./AuthContext"

export const scrollToFirstErrorField = (errors) => {
  if (Object.keys(errors).length === 0) return
  const firstErrorKey = Object.keys(errors)[0]
  const firstErrorDocument = document.getElementsByName(firstErrorKey)
  if (firstErrorDocument.length > 0)
    firstErrorDocument[0].scrollIntoView({
      behavior: "smooth",
      block: "center",
    })
}

export const scrollToFirstErrorFieldById = (emptyFieldId: string) => {
  if (!emptyFieldId) return

  const firstErrorDocument = document.getElementById(emptyFieldId)

  if (firstErrorDocument)
    firstErrorDocument.scrollIntoView({
      behavior: "smooth",
      block: "center",
    })
}
